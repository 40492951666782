import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { graphql } from 'gatsby'
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Footer, Header, SEO } from "components";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import { validateCustomerEmail } from "state/LoginPage/actions";
import { selectLoginState } from "state/LoginPage/reducer";
import * as styles from "./ConfirmEmailPage.module.scss";

const ConfirmEmailPage = (props) => {
  const {
    location,
    loginState,
    menu,
    data,

    validateCustomerEmail,
    resetMenuFilters,
    setMenuCollection,
  } = props;

  const params = new URLSearchParams(location.search);
  const emailConfirmationToken = params.get("token");

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  useEffect(() => {
    validateCustomerEmail({ emailConfirmationToken }).then(
      (isConfirmationSuccessful) => {
        setIsEmailConfirmed(isConfirmationSuccessful);
      }
    );
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS} = data?.markdownRemark?.frontmatter || {}
  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname='/validate-customer-email'
      />
      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
          isNeedDescription={true}
        />

        <div className={styles.form}>
          <div className={styles.title}>Confirm your email</div>
          {isEmailConfirmed && (
            <p className={styles.description}>
              Thank you. Your email is confirmed!
            </p>
          )}
          {loginState.UIState.isLoading && (
            <div className={styles.description}>
              Email confrimation in progress
            </div>
          )}
          <p className={styles.description}>
            Return to{" "}
            <span
              className={styles.link}
              onClick={() => navigate("/login")}
            >
              My Accounts &gt;
            </span>
          </p>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  loginState: selectLoginState(state),
});

const mapDispatchToProps = {
  validateCustomerEmail,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPage);

export const query = graphql`
  query ValidateCustomerEmailPage {
    markdownRemark(fields: {slug: {eq: "/validate-customer-email/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
